export const RoutingTable = Object.freeze({
  'store-manager': 'https://drive.google.com/file/d/1ugMyEgFkJIM5tLpKmlRUwDtCWzi7CYZo/view?usp=share_link', // 特約店正式版 1.1.3 (33).apk
  'store-manager-alpha': 'https://drive.google.com/file/d/13A6R5gu66dHELGr-FwIhfZ8Nmhx-KxwR/view?usp=share_link', // 特約店測試版 1.1.3 (33).apk
  'parking-lot-manager': 'https://autopass-attachments.s3.ap-northeast-1.amazonaws.com/parking-management/parking_management_1_4_0_30.apk', // 車總管正式版 1.4.0 (30).apk
  'parking-lot-manager-alpha': 'https://autopass-attachments.s3.ap-northeast-1.amazonaws.com/parking-management/parking_management_alpha_1_4_0_30.apk', // 車總管測試版 1.4.0 (30).apk
  'gas-station-manager': 'https://autopass-attachments.s3.ap-northeast-1.amazonaws.com/refueling-management/refueling_management_3_10_0_190.apk', // 油總管正式版 3.10.0 (190).apk
  'gas-station-manager-alpha': 'https://autopass-attachments.s3.ap-northeast-1.amazonaws.com/refueling-management/refueling_management_alpha_3_10_0_190.apk', // 油總管測試版 3.10.0 (190).apk
  'lab-devices':'https://www.notion.so/autopass/c2c3f4ef33b4444686c5311ec9649636', // 公司內部測試裝置列表 Notion 文件
  'api-docs': 'https://storage.googleapis.com/pklotcrop-swagger-doc/79455a04b9bb8e812379726aea734db9/index.html', // 公司內部 api 
  '/': 'https://www.autopass.xyz', // fallback url
})